<template>
  <div>
    <p>
      When creating a Metaplex NFT you are
      <a
        href="https://docs.metaplex.com/architecture/deep_dive/overview"
        target="_blank"
        class="nes-text is-primary"
        >asked to provide an array of creators</a
      >.
    </p>
    <p class="mt-5">
      Here you can search by the first creator in the array, also known as the "mint wallet".
    </p>
    <p class="mt-5">It's the same wallet you used to sign the minting transaction.</p>
  </div>
</template>
