import { initializeApp } from 'firebase/app';
import {
    collection,
    addDoc,
    getFirestore,
    getDocs,
    setDoc,
    doc,
    query,
    where,
    orderBy,
    limit,
  } from 'firebase/firestore';

  const firebaseConfig = {
    apiKey: "AIzaSyB44OUE5pUuk5sw1UBpTdX9PdBo52NmnOo",
    authDomain: "goblin-coliseum.firebaseapp.com",
    projectId: "goblin-coliseum",
    storageBucket: "goblin-coliseum.appspot.com",
    messagingSenderId: "4184457433",
    appId: "1:4184457433:web:512eff2f74f2c69a943ec8",
    measurementId: "G-7BY4FYBPW4"
  };

const app = initializeApp(firebaseConfig);
require('firebase/firestore');

export const db = getFirestore(app);