
import { defineComponent, onMounted, ref, Ref } from 'vue';
import { startOfWeek, subDays, endOfWeek } from 'date-fns';
import { collection, getDocs, query, where, orderBy, limit, addDoc } from 'firebase/firestore';
import { playerStore } from '@/stores/player';
import LeaderboardTable from '@/components/LeaderboardTable.vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'tippy.js/dist/tippy.css';
import GoblinNavVue from '@/components/GoblinNav.vue';
import ModalVue from '@/components/Modal.vue';
import { db } from '../firebaseDb';
import { isValidPlayer, getCachedPlayerInfo, setCachedPlayerInfo, updateCachedScore } from '@/persistedPlayer';

const scoresCollection = collection(db, 'coliseum_scores');

export interface ScoreRecord {
  customName: string;
  dateAdded: number;
  nft: string;
  nftImageUrl: string;
  score: number;
  wallet: string;
}

export default defineComponent({
  components: { LeaderboardTable, GoblinNavVue, ModalVue },

  methods: {
    //     async function submitScore(name) {
    //   await recordScore(name);
    //   showModal.value = false;
    // }
    async recordScore(name: string) {
      if (this.cachedPlayerInfo === null) {
        return;
      }

      try {
        const scoreData = {
          customName: name,
          dateAdded: Number(new Date().getTime()),
          nft: this.cachedPlayerInfo.nftName,
          score: this.cachedPlayerInfo?.lastScore,
          nftImageUrl: this.cachedPlayerInfo.imageUrl,
          wallet: this.cachedPlayerInfo.owner,
        };

        console.log(`Debug: recording score for ${JSON.stringify(scoreData)}`);

        const docRef = await addDoc(collection(db, 'coliseum_scores'), scoreData);
        console.log(`Debug: Score submitted. DocRef: ${JSON.stringify(docRef)}`);
        updateCachedScore(0);
      } catch (e) {
        console.log('Debug: Error adding document: ', e);
      }
    },
    notThisTime() {
      this.showModal = false;
      updateCachedScore(0);
    },
    convertToDateString(unixDate: number): String {
      console.log(`Converting ${unixDate}`);
      const date = new Date(unixDate);
      console.log(date);
      return date.toLocaleDateString();
    },
    showAllTimeScores() {
      this.scoreShown = 1;
      console.log(`showing all scores ${this.scoreShown}`);
    },
    async showMyScores() {
      if (this.playerS.owner.length > 0) {
        if (this.myScores.length === 0) {
          const q = await getDocs(
            query(
              scoresCollection,
              where('wallet', '==', this.playerS.owner),
              orderBy('score', 'desc'),
              limit(50)
            )
          );
          this.myScores = [];
          q.forEach((doc) => {
            this.myScores.push({
              customName: doc.data().customName,
              dateAdded: doc.data().dateAdded,
              nft: doc.data().nft,
              nftImageUrl: doc.data().nftImageUrl,
              score: doc.data().score,
              wallet: doc.data().wallet,
            });
          });
        }

        console.log(this.myScores);
        this.scoreShown = 2;
        console.log(`showing my scores ${this.scoreShown}`);
      }
    },
    async filterByWallet() {
      console.log(`Filtering by wallet `);
      const q = await getDocs(query(scoresCollection, where('wallet', '==', this.walletFilter)));
      this.byWalletScores = [];
      q.forEach((doc) => {
        this.byWalletScores.push({
          customName: doc.data().customName,
          dateAdded: doc.data().dateAdded,
          nft: doc.data().nft,
          nftImageUrl: doc.data().nftImageUrl,
          score: doc.data().score,
          wallet: doc.data().wallet,
        });
      });

      this.byWalletScores
        .sort((a, b) => {
          if (a.score > b.score) {
            return -1;
          }
          return 1;
        })
        .slice(50);

      this.scoreShown = 5;
      console.log(`showing last week`);
    },
    async showLastWeek() {
      if (this.lastWeekScores.length === 0) {
        const q = await getDocs(
          query(
            scoresCollection,
            where('dateAdded', '>=', Number(this.lastWeekStart.getTime())),
            where('dateAdded', '<=', Number(this.lastWeekEnd.getTime()))
          )
        );
        this.lastWeekScores = [];
        q.forEach((doc) => {
          this.lastWeekScores.push({
            customName: doc.data().customName,
            dateAdded: doc.data().dateAdded,
            nft: doc.data().nft,
            nftImageUrl: doc.data().nftImageUrl,
            score: doc.data().score,
            wallet: doc.data().wallet,
          });
        });

        this.lastWeekScores
          .sort((a, b) => {
            if (a.score > b.score) {
              return -1;
            }
            return 1;
          })
          .slice(50);
      }

      this.scoreShown = 3;
      console.log(`showing last week`);
    },
    async showThisWeek() {
      if (this.thisWeekScores.length === 0) {
        const q = await getDocs(
          query(
            scoresCollection,
            where('dateAdded', '>=', Number(this.thisWeekStart.getTime())),
            where('dateAdded', '<=', Number(this.thisWeekEnd.getTime()))
          )
        );
        this.thisWeekScores = [];
        q.forEach((doc) => {
          this.thisWeekScores.push({
            customName: doc.data().customName,
            dateAdded: doc.data().dateAdded,
            nft: doc.data().nft,
            nftImageUrl: doc.data().nftImageUrl,
            score: doc.data().score,
            wallet: doc.data().wallet,
          });
        });

        this.thisWeekScores
          .sort((a, b) => {
            if (a.score > b.score) {
              return -1;
            }
            return 1;
          })
          .slice(50);
      }

      this.scoreShown = 4;
      console.log(`showing this week`);
    },
    async submitScore(name: string) {
      await this.recordScore(name);
      this.showModal = false;
      await this.updateAllTimeScores();
    },
  },
  setup() {
    const allTimeScores: Ref<ScoreRecord[]> = ref([]);
    const myScores: Ref<ScoreRecord[]> = ref([]);
    const lastWeekScores: Ref<ScoreRecord[]> = ref([]);
    const thisWeekScores: Ref<ScoreRecord[]> = ref([]);
    const byWalletScores: Ref<ScoreRecord[]> = ref([]);
    const playerS = playerStore();
    const cachedPlayerInfo = getCachedPlayerInfo();
    const showModal = ref(false);

    async function updateAllTimeScores() {
      const q = await getDocs(query(scoresCollection, orderBy('score', 'desc'), limit(50)));

      allTimeScores.value = [];
      q.forEach((doc) => {
        allTimeScores.value.push({
          customName: doc.data().customName,
          dateAdded: doc.data().dateAdded,
          nft: doc.data().nft,
          nftImageUrl: doc.data().nftImageUrl,
          score: doc.data().score,
          wallet: doc.data().wallet,
        });
      });

      // console.log(this.allTimeScores.value);
    }

    onMounted(() => {
      if (typeof cachedPlayerInfo?.lastScore !== 'undefined' && cachedPlayerInfo?.lastScore > 0) {
        console.log(`Debug: showing score modal ${JSON.stringify(cachedPlayerInfo)}`)
        showModal.value = true;
      } else {
        console.log(`Debug: not showing score modal`)
      }
      updateAllTimeScores();
    });

    const todayMinusSeven = subDays(new Date(), 7);
    const lastWeekStart = startOfWeek(todayMinusSeven);
    const lastWeekEnd = endOfWeek(todayMinusSeven);

    const thisWeekStart = startOfWeek(new Date());
    const thisWeekEnd = endOfWeek(new Date());

    return {
      allTimeScores,
      myScores,
      lastWeekScores,
      thisWeekScores,
      cachedPlayerInfo,
      byWalletScores,
      updateAllTimeScores,
      playerS,
      showModal,
      scoreShown: ref(1),
      walletFilter: ref(''),
      lastWeekStart,
      lastWeekEnd,
      thisWeekStart,
      thisWeekEnd,
    };
  },
});

function updateAllTimeScores() {
throw new Error('Function not implemented.');
}
