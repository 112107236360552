import { defineStore } from 'pinia'

export const playerStore = defineStore('counter', {
  state: () => ({ 
      owner: '',
      nftName: '',
      nftImageUrl: '', 
    }),
    // actions: {
    //   updateScore(score: number) {
    //     this.lastScore = score;
    //   }
    // }
  // could also be defined as
  // state: () => ({ count: 0 })
//   actions: {
//     increment() {
//       this.count++
//     },
//   },
})