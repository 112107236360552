
import { defineComponent } from 'vue';
import { playerStore } from '@/stores/player';
import { isValidPlayer, getCachedPlayerInfo } from '@/persistedPlayer';

export default defineComponent({
  methods: { 
    nftImageUrl(): string {
      if (this.playerS.nftImageUrl && this.playerS.nftImageUrl.length > 0) {
        return this.playerS.nftImageUrl;
      }

      const cachedPlayerInfo = getCachedPlayerInfo();
      if (cachedPlayerInfo && cachedPlayerInfo.imageUrl && cachedPlayerInfo.imageUrl.length > 0) {
        return cachedPlayerInfo.imageUrl;
      }

      return "";
    },
    hasNftImageUrl(): boolean {
      if (this.playerS.nftImageUrl && this.playerS.nftImageUrl.length > 0) {
        return true;
      }

      const cachedPlayerInfo = getCachedPlayerInfo();
      if (cachedPlayerInfo && cachedPlayerInfo.imageUrl && cachedPlayerInfo.imageUrl.length > 0) {
        return true;
      }

      return false;
    }
  },
  setup() {

    const playerS = playerStore();
    
    return {
      playerS,
      isValidPlayer
    };
  },
});
