<template>
  <div>
    <GoblinNavVue></GoblinNavVue>

    <div style="width: 1000px; height: 800px">
      <canvas id="canvas" ref="canvasRef" style="width: 100%; height: 100%" />

      <div class="content-center">
        <button @click="fullScreen" ref="fullscreenButton">Full Screen</button>
      </div>

    </div>
  </div>
</template>

<script setup>
import { ref, defineComponent, onMounted, onBeforeUnmount, defineProps } from 'vue';
import UnityWebgl from 'unity-webgl';
import { collection, addDoc, getFirestore, getDocs } from 'firebase/firestore';
import { playerStore } from '@/stores/player';
import router from '@/router';
import { db } from '../firebaseDb';
import GoblinNavVue from '@/components/GoblinNav.vue';
import { ColiseumPlayer, isValidPlayer, getCachedPlayerInfo, updateCachedScore } from '@/persistedPlayer';

/**
Used above in template for debugging

            <div>
        Score: {{ score }}<br />
        <button @click="score++">Increase score</button><br />
        <button @click="showModal = true">Submit Score</button><br />
        <button @click="doDeath">Fake Death</button>
      </div>
 */

// suppresses the occasionall ugly unity error
const oldAlert = window.alert;
window.alert = function (text) {
  console.log(text);
  if (!text.includes('Unity')) oldAlert(text);
  return true;
};

const canvasRef = ref(null);
const fullscreenButton = ref(null);
let unityComponent = null;
const score = ref(0);
const gameType = ref('goblin');

const playerS = playerStore();

onMounted(() => {
  const playerS = playerStore();

  if (isValidPlayer()) {
    const coliseumPlayer = getCachedPlayerInfo();
    console.log(`Debug: player: ${JSON.stringify(coliseumPlayer)}`);

    if (coliseumPlayer) {
      playerS.owner = coliseumPlayer.owner;
      playerS.nftName = coliseumPlayer.nftName;
      playerS.nftImageUrl = coliseumPlayer.imageUrl;

      if (coliseumPlayer.nftName.includes('Rat')) {
        console.log(`Debug: setting to rat!`);
        gameType.value = 'rat';
      }
    } else {
      router.push('/');
      return;
    }
  } else {
    router.push('/');
    return;
  }

  let buildUrl = '/coliseum-goblin/Build';

  if (gameType.value === 'rat') {
    buildUrl = '/coliseum-rat/Build';
  }

  const loaderUrl = `${buildUrl}/goblin_coliseum.loader.js`;
  // const script = document.createElement("script");
  // script.src = loaderUrl;

  const config = {
    loaderUrl: `${buildUrl}/goblin_coliseum.loader.js`,
    dataUrl: `${buildUrl}/goblin_coliseum.data`,
    frameworkUrl: `${buildUrl}/goblin_coliseum.framework.js`,
    codeUrl: `${buildUrl}/goblin_coliseum.wasm`,
  };

  if (!unityComponent) {
    unityComponent = new UnityWebgl(canvasRef.value, config);
  }
});

onBeforeUnmount(() => {
  if (unityComponent) {
    unityComponent.destroy();
    unityComponent = null;
  }
});

const Unity = new UnityWebgl();
// Register functions
Unity.on('showDialog', (data) => {});

Unity.on('updateScore', (data) => {
  // eslint-disable-next-line no-const-assign
  score.value = Number(data);
  updateCachedScore(Number(data));
});

async function recordScore(name) {
  const playerS = playerStore();
  try {
    const scoreData = {
      customName: name,
      dateAdded: Number(new Date().getTime()),
      nft: playerS.nftName,
      score: score.value,
      nftImageUrl: playerS.nftImageUrl,
      wallet: playerS.owner,
    };

    console.log(`Debug: recording score for ${JSON.stringify(scoreData)}`);

    const docRef = await addDoc(collection(db, 'coliseum_scores'), scoreData);
    console.log(`Debug: Score submitted. DocRef: ${JSON.stringify(docRef)}`);
  } catch (e) {
    console.log('Debug: Error adding document: ', e);
  }
}

function doDeath() {

  try {
    unityComponent.unityInstance.SetFullscreen(0);
  } catch (err) {
    console.log(err);
  }

  updateCachedScore(score.value);
  window.location.href = '/leaderboard';
}

Unity.on('death', (data) => {
  doDeath();
});

Unity.on('newGame', () => {
  console.log(`Debug: starting new game. resetting score.`)
  score.value = 0;
});

// Unity.Set

function fullScreen() {
  // unityComponent.SetFullscreen(1);
  unityComponent.unityInstance.SetFullscreen(1);
}

async function submitScore(name) {
  await recordScore(name);
}

</script>

<style scoped></style>
