
import { defineComponent, ref } from 'vue';
// import ViewViewVue from './ViewView.vue';
import ChooseWalletVue from './ChooseWallet.vue';
import GoblinNavVue from '@/components/GoblinNav.vue';

export default defineComponent({
  components: { ChooseWalletVue, GoblinNavVue },
  setup() {
    const showText = ref(false);
    return {
      showText,
    };
  },
});
