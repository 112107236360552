import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "w-1/2" }
const _hoisted_2 = {
  key: 0,
  class: "flex"
}
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfigPane = _resolveComponent("ConfigPane")!
  const _component_QuestionMark = _resolveComponent("QuestionMark")!
  const _component_NFTViewForm = _resolveComponent("NFTViewForm")!
  const _component_LoadingBar = _resolveComponent("LoadingBar")!
  const _component_NotifyError = _resolveComponent("NotifyError")!
  const _component_NFTViewCard = _resolveComponent("NFTViewCard")!
  const _component_ContentTooltipExport = _resolveComponent("ContentTooltipExport")!
  const _component_ModalWindow = _resolveComponent("ModalWindow")!
  const _component_infinite_loading = _resolveComponent("infinite-loading")!
  const _component_AdmissionDeniedModal = _resolveComponent("AdmissionDeniedModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ConfigPane),
    _createVNode(_component_NFTViewForm, {
      "is-loading": _ctx.isLoading,
      onSubmitForm: _ctx.handleSubmitForm
    }, {
      default: _withCtx(() => [
        (_ctx.NFTs.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("button", {
                type: "button",
                class: "nes-btn mr-2",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.copyShareLink && _ctx.copyShareLink(...args)))
              }, _toDisplayString(_ctx.copyText), 1),
              _createElementVNode("button", {
                type: "button",
                class: _normalizeClass(["nes-btn", { 'is-disabled': _ctx.disableExport }]),
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.exportNFTs && _ctx.exportNFTs(...args))),
                disabled: _ctx.disableExport
              }, _toDisplayString(_ctx.exportBtnText), 11, _hoisted_3),
              _createVNode(_component_QuestionMark, {
                class: "ml-1 mt-3",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showModal('tooltipExport')))
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["is-loading", "onSubmitForm"]),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_LoadingBar, {
          key: 0,
          progress: _ctx.progress,
          text: _ctx.text,
          class: "my-5"
        }, null, 8, ["progress", "text"]))
      : (_ctx.isError)
        ? (_openBlock(), _createBlock(_component_NotifyError, {
            key: 1,
            class: "mt-5"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.text), 1)
            ]),
            _: 1
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.NFTs, (n) => {
              return (_openBlock(), _createBlock(_component_NFTViewCard, {
                key: n.mint,
                n: n
              }, null, 8, ["n"]))
            }), 128))
          ])),
    (_ctx.isModalVisible('tooltipExport'))
      ? (_openBlock(), _createBlock(_component_ModalWindow, {
          key: 3,
          title: "Wen export??",
          onHideModal: _cache[3] || (_cache[3] = ($event: any) => (_ctx.hideModal('tooltipExport')))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ContentTooltipExport)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_infinite_loading, {
      onInfinite: _ctx.infiniteHandler,
      identifier: 
        +new Date() //needs to be something thta dynamically updates, or won't work
      ,
      spinner: "spiral"
    }, null, 8, ["onInfinite", "identifier"]),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_component_AdmissionDeniedModal, {
        show: _ctx.showDeniedModal,
        onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showDeniedModal = false))
      }, null, 8, ["show"])
    ]))
  ]))
}