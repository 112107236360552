
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import TheMobileCover from '@/components/TheMobileCover.vue';

export default defineComponent({
  components: { TheMobileCover },
  setup() {
    const windowWidth = ref(window.innerWidth);
    const onWidthChange = () => {
      windowWidth.value = window.innerWidth;
    };
    onMounted(() => window.addEventListener('resize', onWidthChange));
    onUnmounted(() => window.removeEventListener('resize', onWidthChange));

    return {
      windowWidth,
    };
  },
});
