
import { defineComponent, onMounted, ref, Ref, PropType } from 'vue';
import { ScoreRecord } from '@/views/ViewLeaderboard.vue';

export default defineComponent({
  components: {},
  props: {
    records: {
      type: Array as PropType<ScoreRecord[]>,
      required: true,
    },
  },
  methods: {
    convertToDateString(unixDate: number): String {
      console.log(`Converting ${unixDate}`);
      const date = new Date(unixDate);
      console.log(date);
      return date.toLocaleDateString();
    },
  },
  setup() {
    onMounted(() => {});

    return {};
  },
});
