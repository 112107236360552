
import { defineComponent } from 'vue';
import LoadingIcon from '@/components/LoadingIcon.vue';

export default defineComponent({
  components: { LoadingIcon },
  props: {
    progress: Number,
    text: String,
  },
  setup() {
    return {};
  },
});
