
import { defineComponent, ref, onMounted } from 'vue';

export default defineComponent({
  props: {
    show: Boolean,
  },
  emits: ['submit'],
  setup(props, context) {

    const playerName = ref('');
    const disableSubmit = ref(false);

    const emitSubmit = () => {
      console.log(`Debug: submitting score for ${playerName.value}`);
      disableSubmit.value = true;
      context.emit('submit', playerName.value );
    };

    onMounted(() => {
      disableSubmit.value = false;
    });

    return {
      emitSubmit,
      playerName,
      disableSubmit
    };
  },
});
