import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-center text-center" }
const _hoisted_2 = ["value"]
const _hoisted_3 = ["value"]
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingIcon = _resolveComponent("LoadingIcon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.progress > 66)
        ? (_openBlock(), _createElementBlock("progress", {
            key: 0,
            class: "nes-progress is-success",
            value: _ctx.progress,
            max: "100"
          }, null, 8, _hoisted_2))
        : (_ctx.progress > 33)
          ? (_openBlock(), _createElementBlock("progress", {
              key: 1,
              class: "nes-progress is-warning",
              value: _ctx.progress,
              max: "100"
            }, null, 8, _hoisted_3))
          : (_openBlock(), _createElementBlock("progress", {
              key: 2,
              class: "nes-progress is-error",
              value: _ctx.progress,
              max: "100"
            }, null, 8, _hoisted_4)),
      _createVNode(_component_LoadingIcon, null, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.text ?? 'Loading...'), 1)
        ]),
        _: 1
      })
    ])
  ]))
}