
import { defineComponent, ref } from 'vue';
import VueJsonPretty from 'vue-json-pretty';
import { stringifyPubkeysAndBNsInObject } from '@/common/helpers/util';
import useModal from '@/composables/modal';
import ModalWindow from '@/components/ModalWindow.vue';
import ContentTooltipJSON from '@/components/content/tooltip/ContentTooltipJSON.vue';
import QuestionMark from '@/components/QuestionMark.vue';
import useCopy from '@/composables/copy';
import ContentTooltipRarity from '@/components/content/tooltip/ContentTooltipRarity.vue';
import router from '@/router';
import { playerStore } from '@/stores/player';
import ViewGame from '../views/ViewGame.vue';
import { ColiseumPlayer, setCachedPlayerInfo } from '@/persistedPlayer';

export default defineComponent({
  props: {
    n: Object,
  },
  components: {
    ContentTooltipRarity,
    QuestionMark,
    ContentTooltipJSON,
    ModalWindow,
    VueJsonPretty,
    ViewGame,
  },
  methods: {
    startGame() {
      console.log(`Debug: play as with ${JSON.stringify(this.n)}`);

      const nftName = this.n?.metadataExternal.name;
      const imageUrl = this.n?.metadataExternal.properties.files[0].uri;

      this.playerS.nftName = nftName;
      this.playerS.nftImageUrl = imageUrl;

      const player: ColiseumPlayer = {
        nftName,
        imageUrl,
        owner: this.playerS.owner,
        timestamp: Number(new Date().valueOf()),
        lastScore: 0,
      };
      setCachedPlayerInfo(player);

      // window.alert(`Wallet: ${owner}, name: ${nftName}, image: ${imageUrl}`);
      router.push({
        path: '/game',
        params: {
          owner: this.playerS.owner,
          nftName,
          imageUrl,
        },
      });
    },
  },
  setup() {
    const isMaster = (editionType: string) => editionType.toLowerCase().includes('master');
    const fullJSON = ref(false);
    const NOT_FOUND = 'Not found';
    const playerS = playerStore();

    const toggleJSON = () => {
      fullJSON.value = !fullJSON.value;
    };

    // --------------------------------------- clipboard
    const { copyText, doCopyJSON } = useCopy();

    // --------------------------------------- modal
    const { registerModal, isModalVisible, showModal, hideModal } = useModal();
    registerModal('tooltipJSON');
    registerModal('tooltipRarity');

    return {
      isMaster,
      fullJSON,
      toggleJSON,
      stringifyPubkeysAndBNsInObject,
      // clipboard
      copyText,
      doCopyJSON,
      // modal
      isModalVisible,
      showModal,
      hideModal,
      NOT_FOUND,
      playerS,
    };
  },
});
