
import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  emits: ['hide-modal'],
  props: {
    title: String,
  },
  setup(props, context) {
    // needed to be able to cancel the modal using ESC
    onMounted(() => {
      (document.getElementById('modal') as any).focus();
    });

    const emitHide = () => {
      context.emit('hide-modal');
    };
    return {
      emitHide,
    };
  },
});
