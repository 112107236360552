<template>
  <div>
    <p>Rarity Score is a measure of NFT's uniqueness within its collection.</p>
    <p class="mt-5">
      It's calculated using the methodology developed by
      <a href="https://rarity.tools/" target="_blank" class="nes-text is-primary">rarity.tools</a>
      , which you can read more about in
      <a
        class="nes-text is-primary"
        href="https://raritytools.medium.com/ranking-rarity-understanding-rarity-calculation-methods-86ceaeb9b98c"
        target="_blank"
        >this article</a
      >
      (see the last method mentioned).
    </p>
  </div>
</template>
