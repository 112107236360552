
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    external: String,
  },
  setup() {
    return {};
  },
});
