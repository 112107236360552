import { createApp } from 'vue';
import { createPinia } from 'pinia'
import VueTippy from 'vue-tippy'
import App from './App.vue';
import router from './router';
import './index.css';


createApp(App)
  .use(router)
  .use(createPinia())
  .use(
    VueTippy,
    // optional
    {
      directive: 'tippy', // => v-tippy
      component: 'tippy', // => <tippy/>
      componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
      defaultProps: {
        placement: 'auto-end',
        allowHTML: true,
      }, // => Global default options * see all props
    }
  )
  .mount('#app');
