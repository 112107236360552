
import { computed, defineComponent, ref, watch } from 'vue';
import InfiniteLoading from 'vue-infinite-loading';
import 'vue-json-pretty/lib/styles.css';
import ConfigPane from '@/components/ConfigPane.vue';
import LoadingBar from '@/components/LoadingBar.vue';
import { NFTGet } from '@/common/NFTget';
import NFTViewCard from '@/components/NFTViewCard.vue';
import useLoading, { LoadStatus } from '@/composables/loading';
import { EE, ERR_NO_NFTS } from '@/globals';
import { INFT, INFTParams } from '@/common/helpers/types';
import NFTViewForm from '@/components/NFTViewForm.vue';
import useDownload from '@/composables/download';
import useCopy from '@/composables/copy';
import NotifyError from '@/components/notifications/NotifyError.vue';
import QuestionMark from '@/components/QuestionMark.vue';
import ModalWindow from '@/components/ModalWindow.vue';
import ContentTooltipExport from '@/components/content/tooltip/ContentTooltipExport.vue';
import useModal from '@/composables/modal';
import AdmissionDeniedModal from '../components/AdmissionDeniedModal.vue';

export default defineComponent({
  components: {
    ContentTooltipExport,
    ModalWindow,
    QuestionMark,
    NotifyError,
    NFTViewForm,
    NFTViewCard,
    LoadingBar,
    ConfigPane,
    InfiniteLoading,
    AdmissionDeniedModal,
  },
  setup() {
    const {
      progress,
      text,
      isLoading,
      isError,
      updateLoading,
      updateLoadingStdErr,
      updateLoadingStdWin,
    } = useLoading();
    const displayedNFTs = ref<INFT[]>([]); // this is what's shown on FE
    const allFetchedNFTs = ref<INFT[]>([]); // this is everything fetched in mem
    const fetchParams = ref<INFTParams | null>(null);
    const NFTCount = computed(() => displayedNFTs.value.length + allFetchedNFTs.value.length);
    const showDeniedModal = ref(false);
    const analysisStarted = ref(false);

    const getNextBatch = (size: number): INFT[] => {
      if (allFetchedNFTs.value.length === 0) {
        return [];
      }
      if (allFetchedNFTs.value.length > size) {
        console.log(`Debug: getting another batch of ${size} NFTs to enrich.`);
        return allFetchedNFTs.value.splice(0, size);
      }
      console.log(`Debug: getting the rest of the NFTs to enrich.`);
      return allFetchedNFTs.value.splice(0, allFetchedNFTs.value.length);
    };

    const processBatch = (nextBatch: INFT[]) => {
      const validCreators = ['3WCtz5RGa4U3MHQAammvfN6a4rjiujUZLZSYfepD3E6R'];

      if (typeof nextBatch !== 'undefined' && nextBatch.length > 0) {
        const filteredBatch = nextBatch.filter((nft) => {
          if (nft.metadataOnchain.data.creators === null) {
            return false;
          }

          if (
            typeof nft.metadataOnchain === 'undefined' ||
            typeof nft.metadataOnchain.data === 'undefined' ||
            typeof nft.metadataOnchain.data.creators === 'undefined'
          ) {
            return false;
          }

          if (
            typeof nft.metadataExternal === 'undefined' ||
            typeof nft.metadataExternal.name === 'undefined'
          ) {
            return false;
          }

          console.log(`Debug: checking to see if ${nft.address.toString()} should be excluded`);
          let nftIsValid = false;

          for (const addy of nft.metadataOnchain.data.creators) {
            if (validCreators.includes(addy.address.toString())) {
              nftIsValid = true;
            }
          }

          if (nftIsValid && !nft.metadataExternal.name.startsWith('Pixel ')) {
            nftIsValid = false;
          }

          if (!nftIsValid) {
            console.log(`Debug: Excluded ${nft.address.toString()}.`);
            console.log(`Debug: Extended exclusion info: ${JSON.stringify(nft)}`);
            return false;
          }

          console.log(`Debug: INCLUDED ${nft.address.toString()}.`);
          return true;
        });

        if (filteredBatch.length > 0) {
          console.log(`Debug: adding ${filteredBatch.length} NFTs from this batch.`);
          displayedNFTs.value.push(...filteredBatch);
        }
      } else {
        console.log(`Debug: skipping filtering because there are no more`);
      }
    };

    const fetchNFTs = (params: INFTParams) => {
      console.log(`Debug: update loading. loading. ${displayedNFTs.value.length} NFTs`);
      updateLoading({
        newStatus: LoadStatus.Loading,
        newProgress: 0,
        maxProgress: 50,
        newText: 'Looking for NFTs... ETA: <1 min',
      });

      EE.removeAllListeners();
      EE.on('loading', updateLoading);

      // clear for new fetch
      console.log(`Debug: clearing displayedNFTs for new fetch.`);
      displayedNFTs.value = [];
      allFetchedNFTs.value = [];

      NFTGet(params)
        .then((fetchedNFTs) => {
          if (fetchedNFTs.length) {
            console.log(`Debug: Have ${fetchedNFTs.length} NFTS to check.`);
            analysisStarted.value = true;

            allFetchedNFTs.value = fetchedNFTs;

            const nextBatch = getNextBatch(10);

            processBatch(nextBatch);

            console.log(`Debug: update loading. success. ${displayedNFTs.value.length} NFTs`);
            updateLoadingStdWin();
          } else {
            console.log(`Debug: update loading. error. ${displayedNFTs.value.length} NFTs`);
            updateLoadingStdErr(ERR_NO_NFTS);
          }
        })
        .catch(updateLoadingStdErr);
    };

    const handleSubmitForm = (params: INFTParams) => {
      fetchParams.value = params;
      fetchNFTs(params);
      // alert(`Debug: Displayed NFTs after fetch: ${displayedNFTs.value.length}`)
    };

    // --------------------------------------- display
    const infiniteHandler = ($state: any) => {
      console.log(`Debug: Starting infinite handler.`);
      const nextBatch = getNextBatch(10);
      if (nextBatch.length) {
        processBatch(nextBatch);

        console.log(`Debug: ${displayedNFTs.value.length} total NFTs in displayedNFTs.`);

        if (analysisStarted.value === true && displayedNFTs.value.length === 0) {
          console.log(`Debug: showing denied modal.`);
          showDeniedModal.value = true;
        }
        analysisStarted.value = false;

        $state.loaded();
      } else {
        if (analysisStarted.value === true && displayedNFTs.value.length === 0) {
          console.log(`Debug: showing denied modal.`);
          showDeniedModal.value = true;
        }
        analysisStarted.value = false;

        $state.complete();
      }
    };

    // --------------------------------------- export
    const { exportJSONZip } = useDownload();
    const exportBtnText = ref(`Export ${NFTCount.value} NFTs`);
    const disableExport = ref(false);

    watch(NFTCount, (newCount) => {
      exportBtnText.value = `Export ${newCount} NFTs`;
    });

    const parseParams = (): [string, string] => {
      let returnKey: string;
      let returnPk: string;
      for (const [k, v] of Object.entries(fetchParams.value!)) {
        if (v && v instanceof Array) {
          returnKey = k;
          returnPk = v[0].toBase58(); // get the first creator
        } else if (v) {
          returnKey = k;
          returnPk = v.toBase58();
        }
      }
      return [returnKey!, returnPk!];
    };

    const doneExportingCallback = () => {
      disableExport.value = false;
      exportBtnText.value = `Export ${NFTCount.value} NFTs`;
    };

    const exportNFTs = () => {
      disableExport.value = true;
      exportBtnText.value = 'preparing...';
      const allNFTs = displayedNFTs.value.concat(allFetchedNFTs.value);
      const now = +new Date();
      const [k, v] = parseParams();
      exportJSONZip(allNFTs, 'mint', `${k}-${v}-${now}`, doneExportingCallback);
    };

    // --------------------------------------- sharing
    const { copyText, setCopyText, doCopy } = useCopy();
    setCopyText('Share Link');

    const copyShareLink = async () => {
      const host = window.location.origin;
      if (fetchParams.value!.owner) {
        await doCopy(`${host}/view/address/${fetchParams.value!.owner.toBase58()}`);
      } else if (fetchParams.value!.creator) {
        await doCopy(`${host}/view/creator/${fetchParams.value!.creator.toBase58()}`);
      } else if (fetchParams.value!.updateAuthority) {
        await doCopy(`${host}/view/authority/${fetchParams.value!.updateAuthority.toBase58()}`);
      } else if (fetchParams.value!.mint) {
        await doCopy(`${host}/view/mint/${fetchParams.value!.mint.toBase58()}`);
      }
    };

    // --------------------------------------- modal
    const { registerModal, isModalVisible, showModal, hideModal } = useModal();
    registerModal('tooltipExport');

    return {
      NFTs: displayedNFTs,
      progress,
      text,
      isLoading,
      isError,
      exportNFTs,
      handleSubmitForm,
      infiniteHandler,
      // export
      exportBtnText,
      disableExport,
      // share
      copyText,
      copyShareLink,
      showDeniedModal,
      analysisStarted,
      doCopy,
      // modal
      isModalVisible,
      showModal,
      hideModal,
    };
  },
});
