import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, withKeys as _withKeys, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2786ad13"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nes-container bg-white relative" }
const _hoisted_2 = { class: "text-lg underline" }
const _hoisted_3 = { class: "mt-5 text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "fixed inset-0 opacity-50 bg-black z400",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.emitHide && _ctx.emitHide(...args)))
    }),
    _createElementVNode("div", {
      class: "modal",
      onKeydown: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.emitHide && _ctx.emitHide(...args)), ["esc"])),
      tabindex: "0",
      id: "modal"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.title), 1),
        _createElementVNode("p", {
          class: "absolute top-1 right-1",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.emitHide && _ctx.emitHide(...args)))
        }, "X"),
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ])
      ])
    ], 32)
  ], 64))
}