
import { defineComponent, ref } from 'vue';

export default defineComponent({
  props: {
    show: Boolean,
  },
  setup() {
    return {};
  },
});
