export interface ColiseumPlayer {
    nftName: string,
    imageUrl: string,
    owner: string,
    timestamp: number,
    lastScore: number,
}

export function getCachedPlayerInfo(): ColiseumPlayer | null {
    let cachedResults: ColiseumPlayer | null = null;
    if (localStorage.getItem("player")) {
      try {
        const cached = localStorage.getItem("player");
        if (cached != null) {
          cachedResults = JSON.parse(cached);
          return cachedResults
        }
      } catch (e) {
        localStorage.removeItem("player");
      }
    }

    return null;
}

export function setCachedPlayerInfo(player: ColiseumPlayer) {
  const jsonString = JSON.stringify(player);
  localStorage.setItem("player", jsonString);
}

export function updateCachedScore(score: number) {
  const player = getCachedPlayerInfo();

  if (player === null) {
    return;
  }

  player.lastScore = score;

  setCachedPlayerInfo(player);
}

export function isValidPlayer(): boolean {

    const cachedPlayerInfo = getCachedPlayerInfo();
    console.log(`Debug: checking for player validity.`);
    
    if (cachedPlayerInfo) { 
        const now = new Date();
        const datePlanted = cachedPlayerInfo.timestamp;
        const threeDays = 60 * 60 * 1000 * 24 * 3;
        const diff = Number(now.valueOf()) - Number(datePlanted.valueOf());

        if (diff < threeDays) {
            return true;
        } 
        
        // remove player info if its stale
        localStorage.removeItem("player");
        
    } else {
        console.log(`Debug: no cached player info.`)
    }

    return false;
}
